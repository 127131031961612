import AddRoundedIcon from "@mui/icons-material/AddRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subDays, subYears } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import CustomButton from "../../../Component/InputFields/CustomButton/CustomButton";
import CustomCheckbox from "../../../Component/InputFields/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../Component/InputFields/DatePicker/DatePicker";
import RKTextField from "../../../Component/InputFields/RKTextField/RKTextField";
import SelectDropdown from "../../../Component/InputFields/SelectDropdown/SelectDropdown";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import { CLIENTS } from "../../../Routing/Clients";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { HEALTH_ROUTE } from "../../../Routing/Path/HealthRoutes";
import { HEALTH_FORM_SERVICES } from "../../../Services/Health/HealthServices";
import { HealthSlice } from "../../../Store/Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/Store/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  calculateAge,
  calculateAgeInDays,
  isEmpty,
  validateMobileNumber,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { COLORS } from "../../../SupportingFiles/colors";
import { THealthAddForm } from "../../../Type/Health/THealthSlice/THealthSlice";
import "./../../../SCSS/ResponsivePages.scss";
import HealthProductContainer from "../../../Container/HealthProductContainer/HealthProductContainer";

function HealthProductPage() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { Health } = useAppSelector((state) => state);
  const { ADD_FORM, DROPDOWN_DATA } = useAppSelector((state) => state.Health);

  const dispatch = useAppDispatch();

  const [showLoader, setShowLoader] = useState(false);

  const [healthFormSteps, setHealthFormSteps] = useState<"1" | "2">("1");

  const [formData, setFormData] = useState<THealthAddForm>(ADD_FORM);

  const annual_incomme_data: { label: string; value: string }[] = [];
  for (let i = 1; i <= 99; i++) {
    annual_incomme_data.push({ label: `${i}`, value: `${i}` });
  }

  const gender_data = [
    { label: "M", value: "Male" },
    { label: "F", value: "Female" },
  ];
  const relationship_data = [
    { label: "Spouse", value: "Spouse" },
    { label: "Mother", value: "Mother" },
    { label: "Father", value: "Father" },
  ];

  const validate_form = () => {
    let hasError = false;
    let data: any = { ...formData };

    if (healthFormSteps === "1") {
      if (formData.self_status) {
        data = {
          ...data,
          self_dob: {
            ...data.self_dob,
            warning: isEmpty(data.self_dob.value),
          },
          gender: {
            ...data.gender,
            warning: isEmpty(data.gender.value),
          },
        };

        if (data.self_dob.warning || data.gender.warning) {
          hasError = true;
        }
      }

      if (formData.spouse_status) {
        data = {
          ...data,
          spouse_dob: {
            ...data.spouse_dob,
            warning: isEmpty(data.spouse_dob.value),
          },
          spouse_relationship: {
            ...data.spouse_relationship,
            warning: isEmpty(data.spouse_relationship.value),
          },
        };

        if (data.spouse_dob.warning || data.spouse_relationship.warning) {
          hasError = true;
        }
      }

      if (
        (formData.son_status || formData.daughter_status) &&
        (formData.son_count.value > 0 || formData.daughter_count.value > 0)
      ) {
        const childDobFields = [
          "child_one_dob",
          "child_two_dob",
          "child_three_dob",
          "child_four_dob",
        ];
        const sonCount = parseInt(formData.son_count.value, 10);
        const daughterCount = parseInt(formData.daughter_count.value, 10);
        const totalChildren = sonCount + daughterCount;

        for (let i = 0; i < totalChildren; i++) {
          const fieldName = childDobFields[i];
          data = {
            ...data,
            [fieldName]: {
              ...data[fieldName],
              warning: isEmpty(data[fieldName].value),
            },
          };
          hasError = hasError || data[fieldName].warning;
        }
      }

      if (!hasError) {
        setHealthFormSteps("2");
      }
    } else {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: !validateMobileNumber(data.mobile.value),
        },
        pincode: {
          ...data.pincode,
          warning: !validatePincode(data.pincode.value),
        },
      };

      if (data.mobile.warning || data.pincode.warning) {
        hasError = true;
      }

      if (!hasError) {
        ADD_FORM_DETAILS(data);
      }
    }

    setFormData({ ...data });

    if (
      data.self_dob.warning ||
      data.gender.warning ||
      data.spouse_dob.warning ||
      data.spouse_relationship.warning ||
      data.child_one_dob.warning ||
      data.child_two_dob.warning ||
      data.child_three_dob.warning ||
      data.child_four_dob.warning
    ) {
      hasError = true;
    }

    if (!hasError) {
      setHealthFormSteps("2");
    }
  };
  useEffect(() => {
    setFormData((prev: any) => ({
      ...prev,
      spouse_relationship: { value: "", warning: false },
      age: { value: "", warning: false },
      children: "",
      gender: { value: "", warning: false },
      pincode: { value: "", warning: false },
      whom_to_insure: "One Adult",
      mobile: { value: "", warning: false },
      name: { value: "", warning: false },
      son_count: { value: "0", warning: false },
      daughter_count: { value: "0", warning: false },
      self_dob: { value: "", warning: false },
      spouse_dob: { value: "", warning: false },
      son_status: false,
      daughter_status: false,
      self_status: true,
      spouse_status: false,
      child_one_dob: { value: "", warning: false },
      child_two_dob: { value: "", warning: false },
      child_three_dob: { value: "", warning: false },
      child_four_dob: { value: "", warning: false },
    }));
  }, []);
  const ADD_FORM_DETAILS = (data: THealthAddForm) => {
    const onSuccess = (res: any) => {
      setShowLoader(false);
      const results = res.results.response;
      const error = res.results.error;

      if (!error) {
        let dataResponse: any = {
          quote_no: results?.quote_no,
          sum_insured: results.sum_insured,
          module: results?.module,
          insurance_type: results?.insurance_type,
          term: "1",
          cover: results?.cover,
          city: results?.city,
          state: results?.state,
          child: results?.child,
          adult: results?.adult,
          mobile: results?.mobile,
          pincode: results?.pincode,
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            ADD_FORM: data,
          })
        );
        window.location.href = `https://${CLIENTS.MUTHOOT_QUOTE}${HEALTH_ROUTE.QOUTE_PAGE}?type=HP&quote_no=${dataResponse.quote_no}`;
      } else {
        toast.error("Something went wrong");
      }
    };

    const onError = (error: any) => {
      setShowLoader(false);
    };

    // Define the parameters outside the JSX return statement
    const { son_count, daughter_count } = data;
    const total_sons = parseInt(son_count.value);
    const total_daughters = parseInt(daughter_count.value);

    let params = {
      healthSumInsured: "500000",
      healthCover: `${data?.spouse_status ? "2A" : "1A"}${
        total_sons + total_daughters > 0
          ? `${total_sons + total_daughters}C`
          : ""
      }`,
      healthAdult: `${data?.spouse_status ? "2" : "1"}`,
      children: `${total_sons + total_daughters}`,
      healthAge: calculateAge(data.self_dob.value),
      healthRelation: "self",
      healthGender: data.gender.value,
      health2Age: calculateAge(data.spouse_dob.value),
      health2Relation: data.spouse_relationship.value,
      healthName: "",
      healthMobile: data.mobile.value,
      healthPincode: data.pincode.value,
      childgender_1: total_sons >= 1 ? "M" : total_daughters >= 1 ? "F" : "",
      childrelation_1:
        total_sons >= 1 ? "son" : total_daughters >= 1 ? "daughter" : "",
      childage_1: data.child_one_dob.value,
      childgender_2:
        total_sons + total_daughters >= 2 ? (total_sons >= 2 ? "M" : "F") : "",
      childrelation_2:
        total_sons + total_daughters >= 2
          ? total_sons >= 2
            ? "son"
            : "daughter"
          : "",
      childage_2:
        total_sons + total_daughters >= 2 ? data.child_two_dob.value : "",
      childgender_3:
        total_sons + total_daughters >= 3 ? (total_sons >= 3 ? "M" : "F") : "",
      childrelation_3:
        total_sons + total_daughters >= 3
          ? total_sons >= 3
            ? "son"
            : "daughter"
          : "",
      childage_3:
        total_sons + total_daughters >= 3 ? data.child_three_dob.value : "",
      childgender_4:
        total_sons + total_daughters >= 4 ? (total_sons >= 4 ? "M" : "F") : "",
      childrelation_4:
        total_sons + total_daughters >= 4
          ? total_sons >= 4
            ? "son"
            : "daughter"
          : "",
      childage_4:
        total_sons + total_daughters >= 4 ? data.child_four_dob.value : "",
    };

    HEALTH_FORM_SERVICES.ADD_HEALTH_FORM(onSuccess, onError, params);
  };
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "whom_to_insure" || attrName === "children") {
      setFormData((prev: any) => ({ ...prev, [attrName]: value }));
    } else if (attrName === "self") {
      setFormData((prev: any) => ({ ...prev, [attrName]: value }));
    } else if (attrName === "spouse_status") {
      if (!value) {
        setFormData((prev: any) => ({
          ...prev,
          spouse_dob: { value: "", warning: false },
          spouse_relationship: { value: "", warning: false },
        }));
      }
      setFormData((prev: any) => ({ ...prev, [attrName]: value }));
    } else if (attrName === "son_status") {
      if (value === false) {
        setFormData((prev: any) => ({
          ...prev,
          child_one_dob: { value: "", warning: false },
          child_two_dob: { value: "", warning: false },
          child_three_dob: { value: "", warning: false },
          child_four_dob: { value: "", warning: false },
        }));
      }
      setFormData((prev: any) => ({ ...prev, [attrName]: value }));

      if (value === true) {
        if (parseInt(formData.daughter_count.value) < 4) {
          setFormData((prev: any) => ({
            ...prev,
            [attrName]: value,
            son_count: { value: "1", warning: false },
          }));
        }
      } else {
        setFormData((prev: any) => ({
          ...prev,
          [attrName]: value,
          son_count: { value: "0", warning: false },
        }));
      }
    } else if (attrName === "daughter_status") {
      if (value === false) {
        setFormData((prev: any) => ({
          ...prev,
          child_one_dob: { value: "", warning: false },
          child_two_dob: { value: "", warning: false },
          child_three_dob: { value: "", warning: false },
          child_four_dob: { value: "", warning: false },
        }));
      }
      setFormData((prev: any) => ({ ...prev, [attrName]: value }));

      if (value === true) {
        if (parseInt(formData.son_count.value) < 4) {
          setFormData((prev: any) => ({
            ...prev,
            [attrName]: value,
            daughter_count: { value: "1", warning: false },
          }));
        }
      } else {
        setFormData((prev: any) => ({
          ...prev,
          [attrName]: value,
          daughter_count: { value: "0", warning: false },
        }));
      }
    } else if (attrName === "pincode") {
      setFormData((prev: any) => ({
        ...prev,
        [attrName]: { value: value, warning: !validatePincode(value) },
      }));
    } else if (attrName === "mobile") {
      setFormData((prev: any) => ({
        ...prev,
        [attrName]: { value: value, warning: !validateMobileNumber(value) },
      }));
    } else if (attrName === "son_subtract") {
      setFormData((prev) => ({
        ...prev,
        son_count: {
          value:
            parseInt(prev.son_count.value) > 1
              ? parseInt(prev.son_count.value) - 1
              : 1,
          warning: false,
        },
        son_status: (prev.son_count.value = 0 ? prev.son_status : false),
      }));
    } else if (attrName === "daughter_subtract") {
      setFormData((prev) => ({
        ...prev,
        daughter_count: {
          value:
            parseInt(prev.daughter_count.value) > 1
              ? parseInt(prev.daughter_count.value) - 1
              : 1,
          warning: false,
        },
        daughter_status: (prev.daughter_count.value = 0
          ? prev.daughter_status
          : false),
      }));
    } else if (attrName === "daughter_add") {
      setFormData((prev) => ({
        ...prev,
        daughter_count: {
          value:
            parseInt(prev.daughter_count.value) +
              parseInt(prev.son_count.value) <
            4
              ? parseInt(prev.daughter_count.value) + 1
              : parseInt(prev.daughter_count.value),
          warning: false,
        },
      }));
    } else if (attrName === "son_add") {
      setFormData((prev) => ({
        ...prev,
        son_count: {
          value:
            (parseInt(prev?.daughter_count?.value) || 0) +
              (parseInt(prev?.son_count?.value) || 0) <
            4
              ? (parseInt(prev?.son_count?.value) || 0) + 1
              : parseInt(prev?.son_count?.value) || 0,
          warning: false,
        },
      }));
    } else {
      setFormData((prev: any) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    }
  };

  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="redbg-header">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbginnerBox healthPP">
              <h1 style={{ textAlign: "center" }}>Health Insurance</h1>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="productPages-commoncss">
        <Grid
          container
          columnSpacing={3}
          className="row mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <h2 style={{ textAlign: "center", marginBottom: "12px" }}>
              <span>The Importance of</span> Health Insurance
            </h2>
            <p style={{ textAlign: "justify" }}>
              The world around us,is changing very fast, like Life style,
              climate, habits, work culture, Medical & Technological advancement
              and very fastly we are adopting to Nuclear family culture,all
              these made Health Insurance as the most importance insurance
              product to have when you are alive. The health insurance industry
              in India has undergone a sea-change over the last two years. One
              major change has been the perception of the category amongst
              customers, and the need to have a health insurance policy. The
              pandemic has made everyone realise the uncertainties of life and
              their unpreparedness in case of any health-related emergency.
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12} md={6} textAlign={"center"} className="mb-margin2">
            <h2 style={{ marginBottom: "24px" }}>
              Secure <span>your</span> well-being <span> with our </span>
              health coverage.
            </h2>
            <img
              src="./images/insurance-policy-hp.svg"
              alt="insurance-policy"
              className="ins-policy-img"
            />
          </Grid>
          <Grid xs={12} md={6}>
            <Box className="form-box-pp px-3">
              <HealthProductContainer />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <Box className="redlightbox">
              <Grid
                container
                columnSpacing={3}
                className="row"
                alignItems={"center"}
              >
                <Grid xs={12} md={6} className="mb-margin2">
                  <h2 style={{ marginBottom: "12px" }}>
                    <span> What is </span>
                    Health Insurance?
                  </h2>
                  <p style={{ textAlign: "justify" }}>
                    Health Insurance is an arrangement by which the insurance
                    company would pay or reimburse the medical expenses of the
                    individual, for hospitalisation caused due to certain
                    illnesses or accidents. In return for this service, a
                    certain amount, called premium, is paid by the policyholder
                    to the insurance company. Health insurance solutions cover
                    your medical expenses, including critical illnesses, based
                    on the coverage that was decided upon. Herein, the insurance
                    company pays the expenses related to the illness and covered
                    under the policy in lieu of regular payment of premium.
                  </p>
                </Grid>
                <Grid xs={12} md={6} textAlign={"center"}>
                  <img
                    src="./images/health-insurance-img-hp.svg"
                    alt="health-insurance"
                    className="hi-img"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
          alignItems={"center"}
        >
          <Grid xs={12}>
            <h2 style={{ marginBottom: "12px" }}>
              <span> Why is it </span> Essential <span> to </span>
              Obtain <span>Health Insurance?</span>
            </h2>
            <p style={{ textAlign: "justify" }}>
              Everyone aims to be fit and healthy all the time, but we never
              know when a medical emergency or a medical need may arise in
              today’s times. The rising cost of medical treatment should not be
              a deterrent in procuring the best medical care that we can procure
              for ourselves and our loved ones. Providing the best possible
              medical care can cause a severe strain on our financial position,
              and hence on our quality of life. A health insurance policy helps
              us insure against such eventualities. By obtaining a health
              insurance policy, we pay a certain premium to the insurance
              company, in exchange for which, they assure us of covering our
              hospitalisation and treatment expenses up to a pre-agreed sum.
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <Box className="redlightbox">
              {isMobile ? (
                <Grid
                  container
                  columnSpacing={3}
                  className="row"
                  alignItems={"center"}
                  marginBottom={"12px"}
                >
                  <Grid xs={12}>
                    <h2 style={{ marginBottom: "8px", textAlign: "left" }}>
                      <span>How to choose a </span>
                      good Health Insurance <span>Plan?</span>
                    </h2>
                    <p>
                      Here are the aspects to look at to ensure that you buy the
                      best health Insurance policy available in the market!
                    </p>
                    <hr style={{ marginTop: "16px", marginBottom: "8px" }} />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  columnSpacing={3}
                  className="row"
                  alignItems={"center"}
                  marginBottom={"12px"}
                >
                  <Grid xs paddingRight={"0"}>
                    <h2 style={{ marginBottom: "12px" }}>
                      <span>How to choose a </span>
                      good Health Insurance <span>Plan?</span>
                    </h2>
                    <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
                    <p>
                      Here are the aspects to look at to ensure that you buy the
                      best health Insurance policy available in the market!
                    </p>
                  </Grid>
                  <Grid xs="auto" paddingLeft={"0"}>
                    <img
                      src="./images/heart-img-hp.svg"
                      alt="heart"
                      className="heart-img"
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container columnSpacing={3} className="row">
                <Grid xs={12}>
                  <ul className="redTicklist">
                    <li>
                      <h6>Adequate Cover</h6>
                      <p>
                        Asses your needs or what would be needed in case things
                        go wrong. A good rule of thumb is have health insurance
                        coverage that’s about 50% of your annual income.
                      </p>
                    </li>
                    <li>
                      <h6>Place of residence</h6>
                      <p>
                        Cost of medical treatment facilities in and around their
                        location of residence.
                      </p>
                    </li>
                    <li>
                      <h6>Pre-existing diseases</h6>
                      <p>
                        In case the individual or any of the family members have
                        any pre-existing diseases, one should ensure that the
                        insurance cover being taken is adequate to cover the
                        required treatment.
                      </p>
                    </li>
                    <li>
                      <h6>Premium</h6>
                      <p>
                        The amount of premium one can shell out every month or
                        year at ease, without undergoing financial burden.
                      </p>
                    </li>
                    <li>
                      <h6>Type of Health Insurance to be purchased</h6>
                      <p>
                        It is important to assess whether you need to purchase
                        individual or family health insurance. For instance, as
                        the family insurance premium is determined by the age of
                        the eldest family member, the premium for all the
                        members together may shoot up if the eldest member is
                        quite old as compared to other members. It may then be
                        feasible to purchase an individual health insurance
                        policy for that member and family floater for rest.
                      </p>
                    </li>
                    <li>
                      <h6>The earlier the better</h6>
                      <p>
                        It is important to note that none of the insurance
                        companies cover pre-existing diseases at the time of
                        purchasing the health insurance policy for the first few
                        years and there is a waiting period to cover them
                        eventually. Hence, one should buy a health insurance
                        policy earlier in life so that pre-existing diseases, if
                        any, get covered as soon as the initial waiting period
                        is over. In addition, there is a lesser probability of
                        one having many diseases when young.
                      </p>
                    </li>
                    <li>
                      <h6>Cashless hospitalisation</h6>
                      <p>
                        All insurance companies, have tie-ups with certain
                        hospitals, where they offer cashless hospitalisation
                        facilities. Under this arrangement, there is no need to
                        pay the huge hospital bills in cash and then later file
                        a claim request. It saves us from the anxiety to arrange
                        the required funds and also fill out the tedious
                        paperwork related to filing health insurance claims. It
                        is best to choose an insurer that has multiple
                        empanelled hospitals in your vicinity so that you can
                        opt for what is best suited to your needs.
                      </p>
                    </li>
                    <li>
                      <h6>No Claim Bonus</h6>
                      <p>
                        This is a bonus offered by some insurance companies for
                        every year that the health insurance policyholder does
                        not file a claim. One must evaluate the various offers
                        being made by insurance companies before concluding on
                        the best health insurance policy that would suit their
                        needs.
                      </p>
                    </li>
                    <li>
                      <h6>Claim Processing</h6>
                      <p>
                        It is important to select an insurance company with a
                        good track record of clearing claims and making timely
                        payments.
                      </p>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} className="row">
          <Grid xs={12} marginBottom={"80px"} className="mb-margin2">
            <h2>
              <span>Advantages of</span> buying Health Insurance{" "}
              <span>through </span>
              our portal
            </h2>
          </Grid>
          <Grid xs={12} marginBottom={"60px"} className="mb-margin2">
            <ul
              className="advantages_buying_hi "
              style={{ marginBottom: "24px" }}
            >
              <li className="list_box">
                <span>Easier to compare plans</span>
                <Box className="content_box">
                  <p style={{ marginBottom: 0, color: COLORS.black }}>
                    As various products and corresponding plans are available
                    online, It will be easier to compare plans from different
                    insurance companies as well as comparing different variants
                    of the same product
                  </p>
                  <img
                    src="./images/hi_advantage_icon1.svg"
                    alt=""
                    style={{ width: "112px" }}
                  />
                </Box>
              </li>
              <li className="list_box">
                <span>More Convenience</span>
                <Box className="content_box">
                  <p style={{ marginBottom: 0, color: COLORS.black }}>
                    As information regarding various plans are available in the
                    portal, It will be convenient and hassle free to buy policy
                    online as customers do not have to visit the offices of
                    insurance companies or to schedule an appointment with
                    insurance company representative.
                  </p>
                  <img
                    src="./images/hi_advantage_icon2.svg"
                    alt=""
                    style={{ width: "112px" }}
                  />
                </Box>
              </li>
              <li className="list_box">
                <span>Professional advice & After sales service</span>
                <Box className="content_box">
                  <p style={{ marginBottom: 0, color: COLORS.black }}>
                    We are Physi-Digital, We have both physical and digital
                    (online) presence to serve you better
                  </p>
                  <img
                    src="./images/hi_advantage_icon3.svg"
                    alt=""
                    style={{ width: "112px" }}
                  />
                </Box>
              </li>
              <li className="list_box">
                <span>Multiple Payment Options Available</span>
                <Box className="content_box">
                  <Box>
                    <p style={{ marginBottom: "8px", color: COLORS.black }}>
                      Wide variety of payment options available which include
                    </p>
                    <ul className="redTicklist">
                      <li>Debit / Credit Cards</li>
                      <li>Internet Banking</li>
                      <li>Wallets</li>
                      <li>UPI</li>
                    </ul>
                  </Box>
                  <img
                    src="./images/hi_advantage_icon4.svg"
                    alt=""
                    style={{ width: "112px" }}
                  />
                </Box>
              </li>
            </ul>

            <Box className="shadow_box_list">
              <ul className="redTicklist">
                <li>
                  <h6>Policy Available 24*7</h6>
                  <p>Policy can be purchased at any time (24*7)</p>
                </li>
                <li>
                  <h6>Personalised service</h6>
                  <p>
                    As a Broker we provide personalised & professional service
                    to all our customers starting from product selection till
                    claim servicing
                  </p>
                </li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default HealthProductPage;
