import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Footer from "../../../Component/Desktop/Common/Footer/Footer";
import NavBar from "../../../Component/Desktop/Common/NavBar/NavBar";
import MFooter from "../../../Component/Mobile/Common/MFooter/MFooter";
import MNavBar from "../../../Component/Mobile/Common/MNavBar/MNavBar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import "./../../../SCSS/ResponsivePages.scss";
import TWProductContainer from "../../../Container/TWProductContainer/TWProductContainer";

function TWProductPage() {
  const isMobile = useIsMobile();

  return (
    <Box className="responsive-wrapper">
      {isMobile ? <MNavBar /> : <NavBar />}

      <Box className="redbg-header">
        <Grid container columnSpacing={3} className="row">
          <Grid xs={12}>
            <Box className="redbginnerBox bikePP">
              <h1 style={{ textAlign: "center" }}>Two-Wheeler Insurance</h1>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="productPages-commoncss">
        <Grid
          container
          columnSpacing={3}
          className="row mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <p style={{ textAlign: "justify" }}>
              A two wheeler insurance policy is an agreement between the vehicle
              owner and the Insurance company wherein the Insurance company is
              obliged to cover the third party liability arising out of an
              accident and admissible costs of the insured vehicle. As per the
              Motor Vehicle Act 1988, third party Two Wheeler Insurance is
              mandatory in India.
            </p>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
          alignItems={"center"}
        >
          <Grid xs={12} md={6} textAlign={"center"} className="mb-margin2">
            <img
              src="./images/bike_with_umbrella.svg"
              alt="insurance-policy"
              className="left_info_term"
            />
          </Grid>
          <Grid xs={12} md={6}>
            <h2 style={{ marginBottom: "24px" }}>
              Two-Wheeler
              <span> Insurance </span>in <span>Seconds </span>
            </h2>
            <Box className="form-box-pp px-2">
              <TWProductContainer />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <Box className="redlightbox">
              <Grid
                container
                columnSpacing={3}
                className="row"
                alignItems={"center"}
              >
                <Grid xs={12} md={6} className="mb-margin2">
                  <h2 style={{ marginBottom: "12px" }}>
                    <span> What is </span>
                    Two-Wheeler Insurance?
                  </h2>
                  <p style={{ textAlign: "justify" }}>
                    Two-Wheeler Insurance is a type of Motor Insurance Policy
                    designed specifically for two-wheeler vehicles like scooters
                    and bikes. It offers financial protection for the vehicle
                    owners if the insured bike /scooter faces any third party
                    liability or potential losses or damages to vehicle.
                  </p>
                </Grid>
                <Grid xs={12} md={6} textAlign={"center"}>
                  <img
                    src="./images/bike-ins-desc.svg"
                    alt="bike-insurance"
                    className="bike-img"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Box className="carInsurance-types mb-margin2" marginBottom={"60px"}>
          {isMobile ? (
            <Grid
              container
              columnSpacing={3}
              className="row "
              alignItems={"center"}
              marginBottom={"24px"}
            >
              <Grid xs={12} md={6} className="mb-margin2">
                <Box className="redlightbox">
                  <Grid container columnSpacing={3} className="row">
                    <Grid xs={12}>
                      <h2
                        style={{ textAlign: "left", marginBottom: "24px" }}
                        className="mb-margin1"
                      >
                        <span>Types of </span>Two-Wheeler Insurance{" "}
                        <span>Plans</span>
                      </h2>
                      <p>
                        In India, Two wheeler insurance policies are broadly
                        classified into three types
                      </p>
                      <ul className="redTicklist car_redlist">
                        <li>Comprehensive Cover</li>
                        <li>Third Party Liability Cover</li>
                        <li>Standalone Own Damage (OD) Cover</li>
                      </ul>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid xs={12} md={6} textAlign={"center"}>
                <img
                  src="./images/bike-crash.svg"
                  alt="bike-crash"
                  className="car-crash-img"
                />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              columnSpacing={3}
              className="row "
              alignItems={"center"}
              marginBottom={"24px"}
            >
              <Grid xs={12} md={6} textAlign={"center"}>
                <img
                  src="./images/bike-crash.svg"
                  alt="car-crash"
                  className="car-crash-img"
                />
              </Grid>
              <Grid xs={12} md={6}>
                <Box className="redlightbox">
                  <Grid container columnSpacing={3} className="row">
                    <Grid xs={12}>
                      <h2
                        style={{ marginBottom: "24px" }}
                        className="mb-margin1"
                      >
                        <span>Types of </span>Two-Wheeler Insurance{" "}
                        <span>Plans</span>
                      </h2>
                      <ul className="redTicklist car_redlist">
                        <li>Comprehensive Cover</li>
                        <li>Third Party Liability Cover</li>
                        <li>Standalone Own Damage (OD) Cover</li>
                      </ul>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          )}

          <Grid
            container
            columnSpacing={3}
            className="row "
            alignItems={"center"}
            marginBottom={"24px"}
          >
            <Grid xs={12}>
              <h4 style={{ marginBottom: "12px" }}>
                Comprehensive <span>Cover</span>
              </h4>
              <p style={{ textAlign: "justify" }}>
                Comprehensive type of two-wheeler insurance cover is a
                combination of Third party cover plus own damage cover that is,
                the damages incurred to the vehicle and third party due to an
                accident. Comprehensive Insurance cover accidental damage,
                protection against vehicle theft, Cover for natural and man-made
                disasters, Compensation in case of complete loss of the vehicle
                and Financial assistance in case of loss due to a fire
                explosion.
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            className="row "
            alignItems={"center"}
            marginBottom={"24px"}
          >
            <Grid xs={12}>
              <h4 style={{ marginBottom: "12px" }}>
                Third Party <span>Liability Cover</span>
              </h4>
              <p style={{ textAlign: "justify" }}>
                As per the Motor Vehicles Act, 1988 Vehicle owners has to buy
                Third Party Liability Cover (TP) . Third Party Liability
                insurance cover only the losses / damages incurred to a third
                party person ora property by the insured vehicle.
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            columnSpacing={3}
            className="row "
            alignItems={"center"}
            marginBottom={"24px"}
          >
            <Grid xs={12}>
              <h4 style={{ marginBottom: "12px" }}>
                Standalone Own Damage <span>(OD) Cover</span>
              </h4>
              <p style={{ textAlign: "justify" }}>
                A standalone own-damage insurance policy covers only the damages
                incurred to the policy holder’s Scooter / Bike due to any mishap
                happened. The loss incurred due to Fire, natural / man made
                disasters, accident or theft is covered in the policy.
              </p>
            </Grid>
          </Grid>
        </Box>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <h2
              style={{ textAlign: "center", marginBottom: "40px" }}
              className="mb-margin2"
            >
               Comparison <span>between</span> Third Party <span>(TP) vs </span>
              Own -Damage<span>(OD) vs </span>
              Comprehensive <span>Bike Insurance</span>
            </h2>
          </Grid>
          <Grid xs={12}>
            <Box sx={{ overflowX: "auto" }}>
              <table className="comparison-table-bike">
                <thead>
                  <tr>
                    <th>What is Covered?</th>
                    <th>Comprehensive</th>
                    <th>Standalone Own Damage</th>
                    <th>Third Party Liability</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Third-party liabilities</td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/red-cross.svg" alt="red-cross" />
                    </td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                  </tr>
                  <tr>
                    <td>Own damages</td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/red-cross.svg" alt="red-cross" />
                    </td>
                  </tr>
                  <tr>
                    <td>Personal accident cover</td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/red-cross.svg" alt="red-cross" />
                    </td>
                  </tr>
                  <tr>
                    <td>Protection against fire</td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/red-cross.svg" alt="red-cross" />
                    </td>
                  </tr>
                  <tr>
                    <td>Cover against theft</td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/green-tick.svg" alt="green-tick" />
                    </td>
                    <td>
                      <img src="./images/red-cross.svg" alt="red-cross" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row  mb-margin2"
          marginBottom={"60px"}
        >
          <Grid xs={12}>
            <h2 style={{ marginBottom: "24px", textAlign: "center" }}>
              <span>Key </span>Considerations <span>Before </span> Purchasing{" "}
              <span>Two-Wheeler Insurance</span>
            </h2>
          </Grid>
          <Grid xs={12}>
            {isMobile ? (
              <Box
                className="key-consideration-box-mobile"
                textAlign={"center"}
              >
                <ul className="kc_point_list" style={{ marginBottom: "40px" }}>
                  <li>
                    <img src="./images/kc_bike_icon1.svg" alt="" />
                    <p>Insured Declared value</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon2.svg" alt="" />
                    <p>Accident Cover</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon3.svg" alt="" />
                    <p>Cashless facility</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon4.svg" alt="" />
                    <p>Zero Depreciation</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon5.svg" alt="" />
                    <p>Roadside Assistance </p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon6.svg" alt="" />
                    <p>Engine Protector</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon7.svg" alt="" />
                    <p>No Claim Bonus</p>
                  </li>
                </ul>
                <img
                  src="./images/kc_bike_img.svg"
                  alt=""
                  className="kc_bike_img"
                />
              </Box>
            ) : (
              <Box className="key-consideration-box">
                <img
                  src="./images/kc_bike_img.svg"
                  alt=""
                  className="kc_bike_img"
                />
                <ul className="kc_point_list">
                  <li>
                    <img src="./images/kc_bike_icon1.svg" alt="" />
                    <p>Insured Declared value</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon2.svg" alt="" />
                    <p>Accident Cover</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon3.svg" alt="" />
                    <p>Cashless facility</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon4.svg" alt="" />
                    <p>Zero Depreciation</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon5.svg" alt="" />
                    <p>Roadside Assistance </p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon6.svg" alt="" />
                    <p>Engine Protector</p>
                  </li>
                  <li>
                    <img src="./images/kc_bike_icon7.svg" alt="" />
                    <p>No Claim Bonus</p>
                  </li>
                </ul>
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          className="row mb-margin2 tw-coverage-section"
          marginBottom={"60px"}
        >
          <Grid xs={12} marginBottom={"40px"} className="mb-margin2">
            <h2 style={{ textAlign: "center" }}>
              <span>Coverage Under </span>Two-Wheeler Insurance
            </h2>
          </Grid>
          <Grid xs={12} md={6} className="mb-margin1" textAlign={"center"}>
            {!isMobile && <span className="red_tag">What is covered ?</span>}
            <Box className="shadow-box">
              {isMobile && <span className="red_tag">What is covered ?</span>}
              <ul className="redTicklist">
                <li>Natural and Man made disaster coverage</li>
                <li>Own Damage Coverage</li>
                <li>Theft or Burglary Coverage</li>
                <li>Third Party Liabilities Coverage</li>
              </ul>
              <Box textAlign={"right"}>
                <img
                  src="./images/girl_with_pencil.svg"
                  alt=""
                  className="girlpencil_img"
                />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} md={6} className="mb-margin1" textAlign={"center"}>
            {!isMobile && (
              <span className="red_tag">What is not covered ?</span>
            )}
            <Box className="shadow-box">
              {isMobile && (
                <span className="red_tag">What is not covered ?</span>
              )}
              <ul className="redTicklist">
                <li>Damage due to normal wear and tear of the Bike</li>
                <li>Damage incurred due to mechanical / electrical Failure</li>
                <li>Depreciation from regular vehicle usage</li>
                <li>
                  Damage to tyres and tubes in the normal course of running
                </li>
                <li>
                  Damage/loss incurred when the policy holder was driving the
                  two-wheeler with out valid license
                </li>
                <li>
                  Damage caused due to the driver being driving under the
                  influence of alcohol or drugs
                </li>
                <li>Damage due to war, mutiny or nuclear risk.</li>
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
}

export default TWProductPage;
